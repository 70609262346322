var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.coin_bonus')))]},proxy:true}])}),_c('div',{staticClass:"mb-3"},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',{staticClass:"product-container"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"product-container__title"},[_vm._v(" "+_vm._s(_vm.$t('product_page.product_bonus_name'))+" ")])]),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "uz", $$v)},expression:"dataForModal.name.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "ru", $$v)},expression:"dataForModal.name.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":((_vm.$t('enter.title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.name, "en", $$v)},expression:"dataForModal.name.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption,"placeholder":"asd"},model:{value:(_vm.dataForModal.description.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "uz", $$v)},expression:"dataForModal.description.uz"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.description'))+" [RU]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption,"placeholder":"asd"},model:{value:(_vm.dataForModal.description.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "ru", $$v)},expression:"dataForModal.description.ru"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.description'))+" [EN]")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.editorOption,"placeholder":"asd"},model:{value:(_vm.dataForModal.description.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "en", $$v)},expression:"dataForModal.description.en"}}),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.app')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.app'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":true,"options":_vm.merchantOptions,"placeholder":_vm.$t('choose.merchant'),"searchable":false,"label":"name"},model:{value:(_vm.dataForModal.merchant),callback:function ($$v) {_vm.$set(_vm.dataForModal, "merchant", $$v)},expression:"dataForModal.merchant"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.type')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.typeOptions,"placeholder":_vm.$t('choose.type'),"searchable":false},on:{"input":function($event){return _vm.clearInput(_vm.dataForModal.type)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (option.label))))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (option.label))))+" ")]}}],null,true),model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.duration_type')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.duration_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.durationTypeOptions,"placeholder":_vm.$t('choose.duration_type'),"searchable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (option.label))))+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t(("titles." + (option.label))))+" ")]}}],null,true),model:{value:(_vm.dataForModal.duration_type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "duration_type", $$v)},expression:"dataForModal.duration_type"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.duration')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.duration'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.duration'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.duration),callback:function ($$v) {_vm.$set(_vm.dataForModal, "duration", $$v)},expression:"dataForModal.duration"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(
                                _vm.dataForModal.type === 'paynet' || _vm.dataForModal.type === 'level'
                            )?_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.value')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.value'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.value'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.value),callback:function ($$v) {_vm.$set(_vm.dataForModal, "value", $$v)},expression:"dataForModal.value"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2007720444)})],1):_vm._e(),(_vm.dataForModal.type !== 'paynet')?_c('b-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.package')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.package'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [(_vm.dataForModal.type === 'level')?_c('v-select',{attrs:{"options":_vm.levelPackageOptions,"placeholder":_vm.$t('enter.packageID'),"searchable":false},model:{value:(_vm.dataForModal.package),callback:function ($$v) {_vm.$set(_vm.dataForModal, "package", $$v)},expression:"dataForModal.package"}}):_vm._e(),(_vm.dataForModal.type === 'service')?_c('v-select',{attrs:{"options":_vm.serviceOptions,"placeholder":_vm.$t('enter.values'),"reduce":function (pack) { return pack.id; },"searchable":false,"label":"type"},model:{value:(_vm.dataForModal.package),callback:function ($$v) {_vm.$set(_vm.dataForModal, "package", $$v)},expression:"dataForModal.package"}}):(
                                        _vm.dataForModal.type === 'sms' ||
                                        _vm.dataForModal.type === 'internet' ||
                                        _vm.dataForModal.type === 'voice'
                                    )?_c('v-select',{attrs:{"options":_vm.packageOptions,"placeholder":_vm.$t('enter.packageID'),"reduce":function (pack) { return pack.id; },"searchable":false,"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.type_name + ' ' + option.qty)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.type_name + ' ' + option.qty)+" ")]}}],null,false,1926189517),model:{value:(_vm.dataForModal.package),callback:function ($$v) {_vm.$set(_vm.dataForModal, "package", $$v)},expression:"dataForModal.package"}}):_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.packageID'),"state":errors.length > 0 ? false : null,"size":"md","type":"text"},model:{value:(_vm.dataForModal.package),callback:function ($$v) {_vm.$set(_vm.dataForModal, "package", $$v)},expression:"dataForModal.package"}}),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1231695933)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-radio-group',{attrs:{"id":"radio-group-2","name":"radio-sub-component"},on:{"input":_vm.checkInfinity},model:{value:(_vm.infinityQuantity),callback:function ($$v) {_vm.infinityQuantity=$$v},expression:"infinityQuantity"}},[_c('b-form-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('titles.not_infinity_quantity'))+" ")]),_c('b-form-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('titles.infinity_quantity'))+" ")])],1)],1)],1),(!_vm.infinityQuantity)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.qty')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.qty'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.qty'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.qty),callback:function ($$v) {_vm.$set(_vm.dataForModal, "qty", $$v)},expression:"dataForModal.qty"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3924336508)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.price')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.price'),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.price'),"state":errors.length > 0 ? false : null,"size":"md","type":"number"},model:{value:(_vm.dataForModal.price),callback:function ($$v) {_vm.$set(_vm.dataForModal, "price", $$v)},expression:"dataForModal.price"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.price_discount')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":!_vm.hasDiscount,"placeholder":_vm.$t('enter.price_discount'),"size":"md","type":"number"},model:{value:(_vm.dataForModal.price_discount),callback:function ($$v) {_vm.$set(_vm.dataForModal, "price_discount", $$v)},expression:"dataForModal.price_discount"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',{attrs:{"for":"is_published"}},[_vm._v(_vm._s(_vm.$t('titles.is_published')))]),_c('b-form-checkbox',{attrs:{"id":"is_published"},model:{value:(_vm.dataForModal.is_published),callback:function ($$v) {_vm.$set(_vm.dataForModal, "is_published", $$v)},expression:"dataForModal.is_published"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',{attrs:{"for":"hasDiscount"}},[_vm._v(_vm._s(_vm.$t('titles.unlock_discount')))]),_c('b-form-checkbox',{attrs:{"id":"hasDiscount"},model:{value:(_vm.hasDiscount),callback:function ($$v) {_vm.hasDiscount=$$v},expression:"hasDiscount"}})],1)],1),_c('b-row',{staticClass:"product-container"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"product-container__title"},[_vm._v(" "+_vm._s(_vm.$t('product_page.product_images'))+" ")])]),_c('div',{staticClass:"w-100 mb-2"},[_c('label',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('titles.image')))]),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.image'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"accept":'image/*,.zip',"deletable":true,"errorText":{
                                            type: _vm.$t('vue_file_agent.file_type_error'),
                                            size: _vm.$t('vue_file_agent.size_type_error')
                                        },"helpText":_vm.$t('vue_file_agent.choose_image'),"maxFiles":14,"maxSize":'10MB',"meta":true,"multiple":false,"theme":'grid'},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)},"select":function($event){return _vm.filesSelected($event)}},model:{value:(_vm.dataForModal.images),callback:function ($$v) {_vm.$set(_vm.dataForModal, "images", $$v)},expression:"dataForModal.images"}}),_c('b-button',{staticClass:"float-right mt-1",attrs:{"disabled":!_vm.fileRecordsForUpload.length || _vm.imageUploaded,"variant":_vm.imageUploaded ? 'success' : 'primary'},on:{"click":function($event){return _vm.getImageId()}}},[(!_vm.imageUploaded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.upload_image'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.image_uploaded'))+" ")])]),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)])],1)],1),_c('b-col',{staticClass:"phone",attrs:{"cols":"4"}},[_c('b-tabs',{attrs:{"align":"left"}},[_c('b-tab',{attrs:{"title":((_vm.$t('product_page.product_tab_page')) + " 1")}},[_c('div',{staticClass:"phone-frame"},[_c('PhoneListView',{attrs:{"product":_vm.dataForModal,"type":'bonus'}})],1)])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end align-items-center my-2",attrs:{"cols":"8"}},[(!_vm.updatingItem)?_c('b-form-checkbox',{staticClass:"mr-auto",model:{value:(_vm.createOneMore),callback:function ($$v) {_vm.createOneMore=$$v},expression:"createOneMore"}},[_vm._v(" "+_vm._s(_vm.$t('titles.add_more'))+" ")]):_vm._e(),_c('b-button',{attrs:{"disabled":_vm.loadingImage,"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }