<script>
import api from '@/services/api';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { copyObjectWithoutID } from '@/util/helper';
import vSelect from 'vue-select';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BRow,
    BTab,
    BTabs
} from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle.vue';
import PhoneListView from '@/views/coin/product/PhoneFrame/PhoneListView.vue';
import PasteImageFileAgent from '@/mixins/pasteImageFileAgent';

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor';
import { hasOwnProperty } from '@/util/object.util';
import { isArray, isNUNEZ, isObject } from '@/util/inspect.util';
import { MERCHANT_ID } from '@/constants/names';
import services from '@/services';
// import { logApi } from '@/services/log/log.service';

export default {
    name: 'LevelProduct',
    mixins: [CRUDPageMixin, PasteImageFileAgent],
    components: {
        PhoneListView,
        PageTitle,
        vSelect,
        BButton,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BTabs,
        BTab,
        quillEditor
    },

    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ align: [] }],
                        ['clean']
                    ]
                }
            },
            items: [],
            merchantOptions: [],
            typeOptions: [],
            durationTypeOptions: [],
            serviceOptions: [],
            levelPackageOptions: ['Ucell', 'Uztelecom'],
            packageOptions: [],
            findOnePack: null,
            update: {
                merchant: []
            },
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                images: [],
                merchant: [],
                price: null,
                duration: 24,
                duration_type: 'hours',
                type: null,
                qty: null,
                value: null,
                package: null,
                price_discount: null,
                is_published: false
            },
            infinityQuantity: false,
            isUpdatingPage: false,
            updatingItem: null,
            requestPending: false,

            fileRecords: [],
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            hasDiscount: false
        };
    },

    computed: {
        isLevelType() {
            return this.dataForModal.type === 'level';
        },
        disableDurationType() {
            const whiteList = ['level', 'internet', 'voice', 'sms'];
            return !whiteList.includes(this.dataForModal.type);
        },
        isUpdatingRole() {
            const hasParamsId = hasOwnProperty(this.$route.params, 'id');
            return !!(hasParamsId && isNUNEZ(this.$route.params.id));
        }
    },

    watch: {
        'dataForModal.type'(pType) {
            if (pType === 'voice' || pType === 'sms' || pType === 'internet') {
                this.getPackages();
            }
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getMerchants();
        await this.getTypes();
        await this.getDurationTypes();
        await this.getPackages();
        await this.fetchServices();
        const params = this.$route.params;
        if (params?.id) {
            await this.fetchProduct(params.id);
            this.isUpdatingPage = true;
        }
        this.requestPending = false;
    },

    methods: {
        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 30
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.merchantOptions = response.data.result;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getTypes() {
            const body = {
                params: {
                    page: 1,
                    limit: 50
                }
            };
            await api.coin
                .fetchBonusTypes(body)
                .then((response) => {
                    this.typeOptions = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getDurationTypes() {
            const body = {
                params: {
                    page: 1,
                    limit: 50
                }
            };
            await api.coin
                .fetchBonusDurationTypes(body)
                .then((response) => {
                    this.durationTypeOptions = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getPackages() {
            // const merchantId = parseInt(MERCHANT_ID);
            const body = {
                page: 1,
                limit: 200
            };
            await api.billingPackages
                .fetchAllItems(body)
                .then((res) => {
                    this.packageOptions = res.data.data.list;
                })
                .catch((e) => {
                    console.error(e);
                });
        },

        clearInput(item) {
            if (item) {
                this.dataForModal.package = null;
            }
        },

        async fetchProduct(uuid) {
            const body = {
                uuid: uuid
            };
            await api.coin
                .fetchCoinBonus(body)
                .then((response) => {
                    const data = response.data.result;
                    if (data.qty === -1) {
                        this.infinityQuantity = true;
                    }
                    const pack = this.packageOptions.find(
                        (item) => item.id === parseInt(data.package)
                    );

                    if (pack) {
                        data.package = pack;
                    } else {
                        data.package = null;
                    }

                    if (data.type === 'level' || data.type === 'paynet') {
                        this.openModalToUpdate(data);
                    } else {
                        if (pack && pack.id) {
                            this.openModalToUpdate({ ...data, package: pack.id });
                        } else {
                            this.openModalToUpdate(data);
                        }
                    }
                    this.dataForModal.price_discount = data['discount_percentage'];
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchServices() {
            const params = {
                page: 1,
                limit: 200,
                merchant_id: parseInt(MERCHANT_ID)
            };

            try {
                const { data } = await services.subscribersService.fetchAll(params);
                this.serviceOptions = data.data.list;
            } catch (e) {
                console.error(e);
            }
        },

        openModalToUpdate(item) {
            this.dataForModal = copyObjectWithoutID(item);
            this.update.merchant = [Object.assign({}, this.dataForModal.merchant)];

            this.updatingItem = item;
            Object.keys(this.updatingItem).forEach((key) => {
                if (key === 'role') {
                    this.dataForModal.role_id = this.dataForModal.role?.id;
                    delete this.dataForModal.role;
                }

                if (key === 'input_type') {
                    if (this.dataForModal.input_type?.id)
                        this.dataForModal.input_type_id = this.dataForModal.input_type.id;
                    delete this.dataForModal.input_type;
                }

                if (key === 'dependent_survey') {
                    if (this.dataForModal.dependent_survey?.uuid)
                        this.dataForModal.dependent_survey_id =
                            this.dataForModal.dependent_survey.uuid;
                    delete this.dataForModal.dependent_survey;
                }

                if (key === 'gifts') {
                    this.dataForModal.gift_id = this.dataForModal.gifts.map((gift) => {
                        return (gift = gift.id);
                    });
                    delete this.dataForModal.gifts;
                }

                if (key === 'level') {
                    this.dataForModal.level_id = this.dataForModal.level?.id;
                    delete this.dataForModal.level;
                }

                if (key === 'warehouse') {
                    if (this.warehouseOptions) {
                        const idx = this.warehouseOptions.findIndex(
                            (item) => item.id === this.dataForModal?.warehouse?.id
                        );
                        if (idx !== -1) {
                            const iMerchant = Object.assign({}, this.warehouseOptions[idx]);
                            this.dataForModal.merchant = [iMerchant];
                            delete this.dataForModal.warehouse_id;
                        }
                    }
                }

                if (key === 'category') {
                    if (this.categoryOptions) {
                        const idx = this.categoryOptions.findIndex(
                            (item) => item.id === this.dataForModal?.category?.id
                        );
                        if (idx !== -1) {
                            this.dataForModal.category = this.categoryOptions[idx];
                            delete this.dataForModal.category_id;
                        }
                    }
                }

                if (key === 'image') {
                    this.dataForModal.image;
                    // this.dataForModal.image_id = this.dataForModal.image?.id
                    // delete this.dataForModal.image
                }

                if (key === 'buttons' && !this.updatingItem[key]) {
                    this.dataForModal.buttons = {
                        name: {
                            uz: '',
                            ru: '',
                            en: ''
                        },
                        link: ''
                    };
                }

                if (key === 'measurement' && typeof this.dataForModal.measurement === 'object') {
                    this.dataForModal.measurement = {
                        id: this.dataForModal.measurement?.id,
                        name: this.checkLocales(this.dataForModal.measurement?.name)
                    };
                }

                if (key === 'images') {
                    for (let i = 0; i < this.dataForModal.images.length; i++) {
                        const image = this.dataForModal.images[i];
                        this.dataForModal.images[i] = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }
                }
            });

            this.isOpenModal = true;
        },

        async createProduct(body, config) {
            try {
                return await api.coin.createCoinBonus(body, config);
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },

        async updateProduct(body, config) {
            try {
                return await api.coin.updateCoinBonus(body, config);
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    try {
                        let { merchant } = this.dataForModal;
                        const pList = [];

                        if (isObject(merchant) && !isArray(merchant)) {
                            merchant = [merchant];
                        }

                        for (let i = 0; i < merchant.length; i++) {
                            const isForUpdate = this.update.merchant.findIndex(
                                (m) => m.id === merchant[i].id
                            );

                            pList.push(
                                this.generateParamsForSubmit({
                                    merchant: merchant[i],
                                    method: isForUpdate !== -1 ? 'update' : 'create'
                                })
                            );
                        }

                        await Promise.all(pList).then(async () => {
                            if (!this.updatingItem) {
                                this.$toast.success(this.$t('success.updated.title'));

                                await this.$router.push({ name: 'coin-bonus' });
                            } else {
                                this.$toast.success(this.$t('success.added'));

                                if (this.createOneMore) {
                                    setTimeout(() => {
                                        location.reload();
                                    }, 500);
                                } else {
                                    await this.$router.push({ name: 'coin-bonus' });
                                }
                            }
                        });
                    } catch (e) {
                        this.$toast.error(
                            e.response?.data?.message || e.message || this.$t('error.smth')
                        );
                    }
                }
            });
        },

        async generateParamsForSubmit({ merchant, method = 'create' }) {
            let body = Object.assign(
                {},
                {
                    merchant_id: merchant.id,
                    type: this.dataForModal.type,
                    name: this.dataForModal.name,
                    package: this.dataForModal.package,
                    value: this.dataForModal.value,
                    description: this.dataForModal.description,
                    price: parseInt(this.dataForModal.price),
                    price_discount: parseInt(this.dataForModal.price_discount),
                    qty: parseInt(this.dataForModal.qty),
                    duration: this.dataForModal.duration,
                    duration_type: this.dataForModal.duration_type,
                    is_published: this.dataForModal.is_published,
                    images: this.dataForModal.images.map((image, position) => {
                        return {
                            position,
                            id: image.id
                        };
                    })
                }
            );

            if (body.duration_type === 'minutes') {
                body.duration = 10 * 60;
                body.duration_type = 'seconds';
            }

            if (body.price_discount) {
                body.price_discount = body.price * (1 - body.price_discount / 100);
            }

            if (body.type === 'internet' || body.type === 'sms' || body.type === 'voice') {
                body.value = 0;
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                if (method === 'update') {
                    body.uuid = this.updatingItem.uuid;
                    return await this.updateProduct(body, config);
                } else {
                    return await this.createProduct(body, config);
                }
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },

        checkInfinity(e) {
            if (e) {
                this.dataForModal.qty = -1;
            } else {
                this.dataForModal.qty = null;
            }
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('products', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('navigation.coin_bonus') }}</template>
        </page-title>

        <div class="mb-3">
            <b-row>
                <b-col cols="8">
                    <ValidationObserver ref="createUpdateModal">
                        <!--  PRODUCT NAME  -->
                        <b-row class="product-container">
                            <div class="col-12">
                                <p class="product-container__title">
                                    {{ $t('product_page.product_bonus_name') }}
                                </p>
                            </div>
                            <!--    NAME[UZ]    -->
                            <b-col cols="4">
                                <label> {{ $t('titles.title') }} [UZ] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.title')} [UZ]`"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.name.uz"
                                            :placeholder="`${$t('enter.title')} [UZ]`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    NAME[RU]    -->
                            <b-col cols="4">
                                <label> {{ $t('titles.title') }} [RU] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.title')} [RU]`"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.name.ru"
                                            :placeholder="`${$t('enter.title')} [RU]`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--    NAME[EN]    -->
                            <b-col cols="4">
                                <label> {{ $t('titles.title') }} [EN] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.title')} [EN]`"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.name.en"
                                            :placeholder="`${$t('enter.title')} [EN]`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--    DESCRIPTION[UZ]    -->
                            <b-col cols="12">
                                <label> {{ $t('titles.description') }} [UZ] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.description')} [UZ]`"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="dataForModal.description.uz"
                                        :options="editorOption"
                                        placeholder="asd"
                                    ></quill-editor>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    DESCRIPTION[RU]    -->
                            <b-col cols="12">
                                <label>{{ $t('titles.description') }} [RU]</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.description')} [RU]`"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="dataForModal.description.ru"
                                        :options="editorOption"
                                        placeholder="asd"
                                    ></quill-editor>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    DESCRIPTION[EN]    -->
                            <b-col cols="12">
                                <label>{{ $t('titles.description') }} [EN]</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.description')} [EN]`"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="dataForModal.description.en"
                                        :options="editorOption"
                                        placeholder="asd"
                                    ></quill-editor>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--     MERCHANT     -->
                            <b-col cols="6">
                                <label>{{ $t('titles.app') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.app')"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="dataForModal.merchant"
                                        :multiple="true"
                                        :options="merchantOptions"
                                        :placeholder="$t('choose.merchant')"
                                        :searchable="false"
                                        label="name"
                                    />
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     TYPE     -->
                            <b-col cols="6">
                                <label>{{ $t('titles.type') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.type')"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="dataForModal.type"
                                        :options="typeOptions"
                                        :placeholder="$t('choose.type')"
                                        :searchable="false"
                                        @input="clearInput(dataForModal.type)"
                                    >
                                        <template v-slot:option="option">
                                            {{ $t(`titles.${option.label}`) }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ $t(`titles.${option.label}`) }}
                                        </template>
                                    </v-select>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     DURATION TYPE     -->
                            <b-col cols="6">
                                <label>{{ $t('titles.duration_type') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.duration_type')"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="dataForModal.duration_type"
                                        :options="durationTypeOptions"
                                        :placeholder="$t('choose.duration_type')"
                                        :searchable="false"
                                    >
                                        <template v-slot:option="option">
                                            {{ $t(`titles.${option.label}`) }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ $t(`titles.${option.label}`) }}
                                        </template>
                                    </v-select>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     DURATION     -->
                            <b-col cols="6">
                                <label>{{ $t('titles.duration') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.duration')"
                                    rules="required|numeric"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.duration"
                                            :placeholder="$t('enter.duration')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     VALUE     -->
                            <b-col
                                v-if="
                                    dataForModal.type === 'paynet' || dataForModal.type === 'level'
                                "
                                cols="6"
                            >
                                <label>{{ $t('titles.value') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.value')"
                                    rules="required|numeric"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.value"
                                            :placeholder="$t('enter.value')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--    PACKAGE ID    -->
                            <b-col v-if="dataForModal.type !== 'paynet'" cols="6">
                                <label>{{ $t('titles.package') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.package')"
                                    rules="required"
                                >
                                    <v-select
                                        v-if="dataForModal.type === 'level'"
                                        v-model="dataForModal.package"
                                        :options="levelPackageOptions"
                                        :placeholder="$t('enter.packageID')"
                                        :searchable="false"
                                    />

                                    <v-select
                                        v-if="dataForModal.type === 'service'"
                                        v-model="dataForModal.package"
                                        :options="serviceOptions"
                                        :placeholder="$t('enter.values')"
                                        :reduce="(pack) => pack.id"
                                        :searchable="false"
                                        label="type"
                                    />

                                    <v-select
                                        v-else-if="
                                            dataForModal.type === 'sms' ||
                                            dataForModal.type === 'internet' ||
                                            dataForModal.type === 'voice'
                                        "
                                        v-model="dataForModal.package"
                                        :options="packageOptions"
                                        :placeholder="$t('enter.packageID')"
                                        :reduce="(pack) => pack.id"
                                        :searchable="false"
                                        label="name"
                                    >
                                        <template v-slot:option="option">
                                            {{ option.type_name + ' ' + option.qty }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ option.type_name + ' ' + option.qty }}
                                        </template>
                                    </v-select>

                                    <b-form-input
                                        v-else
                                        v-model="dataForModal.package"
                                        :placeholder="$t('enter.packageID')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                        type="text"
                                    />

                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     RADIO FOR QTY     -->
                            <b-col cols="12">
                                <b-form-group>
                                    <b-form-radio-group
                                        id="radio-group-2"
                                        v-model="infinityQuantity"
                                        name="radio-sub-component"
                                        @input="checkInfinity"
                                    >
                                        <b-form-radio :value="false"
                                            >{{ $t('titles.not_infinity_quantity') }}
                                        </b-form-radio>
                                        <b-form-radio :value="true"
                                            >{{ $t('titles.infinity_quantity') }}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-col>

                            <!--     QTY     -->
                            <b-col v-if="!infinityQuantity" cols="12">
                                <label>{{ $t('titles.qty') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.qty')"
                                    rules="required|numeric"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.qty"
                                            :placeholder="$t('enter.qty')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     PRICE     -->
                            <b-col cols="12">
                                <label>{{ $t('titles.price') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.price')"
                                    rules="required|numeric"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.price"
                                            :placeholder="$t('enter.price')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     PRICE DISCOUNT     -->
                            <b-col cols="12">
                                <label>{{ $t('titles.price_discount') }}</label>
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.price_discount"
                                        :disabled="!hasDiscount"
                                        :placeholder="$t('enter.price_discount')"
                                        size="md"
                                        type="number"
                                    />
                                </b-form-group>
                            </b-col>

                            <!--    IS PUBLISHED?    -->
                            <b-col cols="6">
                                <label for="is_published">{{ $t('titles.is_published') }}</label>
                                <b-form-checkbox
                                    id="is_published"
                                    v-model="dataForModal.is_published"
                                />
                            </b-col>

                            <!--    HAS DISCOUNT    -->
                            <b-col cols="6">
                                <label for="hasDiscount">{{ $t('titles.unlock_discount') }}</label>
                                <b-form-checkbox id="hasDiscount" v-model="hasDiscount" />
                            </b-col>
                        </b-row>

                        <!--  PRODUCT IMAGE  -->
                        <b-row class="product-container">
                            <div class="col-12">
                                <p class="product-container__title">
                                    {{ $t('product_page.product_images') }}
                                </p>
                            </div>

                            <!--     IMAGE     -->
                            <div class="w-100 mb-2">
                                <label class="pl-1">{{ $t('titles.image') }}</label>

                                <b-col cols="12">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="$t('titles.image')"
                                        rules="required"
                                    >
                                        <VueFileAgent
                                            ref="vueFileAgent"
                                            v-model="dataForModal.images"
                                            :accept="'image/*,.zip'"
                                            :deletable="true"
                                            :errorText="{
                                                type: $t('vue_file_agent.file_type_error'),
                                                size: $t('vue_file_agent.size_type_error')
                                            }"
                                            :helpText="$t('vue_file_agent.choose_image')"
                                            :maxFiles="14"
                                            :maxSize="'10MB'"
                                            :meta="true"
                                            :multiple="false"
                                            :theme="'grid'"
                                            @beforedelete="onBeforeDelete($event)"
                                            @delete="fileDeleted($event)"
                                            @select="filesSelected($event)"
                                        >
                                        </VueFileAgent>

                                        <b-button
                                            :disabled="
                                                !fileRecordsForUpload.length || imageUploaded
                                            "
                                            :variant="imageUploaded ? 'success' : 'primary'"
                                            class="float-right mt-1"
                                            @click="getImageId()"
                                        >
                                            <span v-if="!imageUploaded">
                                                {{ $t('vue_file_agent.upload_image') }}
                                            </span>
                                            <span v-else>
                                                {{ $t('vue_file_agent.image_uploaded') }}
                                            </span>
                                        </b-button>
                                        <span class="validation__red">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </b-col>
                            </div>
                        </b-row>
                    </ValidationObserver>
                </b-col>
                <b-col class="phone" cols="4">
                    <b-tabs align="left">
                        <b-tab :title="`${$t('product_page.product_tab_page')} 1`">
                            <div class="phone-frame">
                                <PhoneListView :product="dataForModal" :type="'bonus'" />
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex justify-content-end align-items-center my-2" cols="8">
                    <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                        {{ $t('titles.add_more') }}
                    </b-form-checkbox>
                    <b-button :disabled="loadingImage" variant="success" @click="submitModal">
                        {{ modalSubmitBtnText }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.product-container__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    color: #4b465c;
}

.product-container {
    display: flex;
    margin: 0;
    row-gap: 1rem;
    padding: 2rem 1rem;
    border: 1px solid #dbdade;
}

.phone {
    position: relative;

    &-frame {
        overflow: hidden;
        position: sticky;
        padding: 40px 1rem 1rem;
        top: 100px;
        border-radius: 1rem;
        height: 623px;
        width: 100%;
        max-width: 302px;
        background-repeat: no-repeat;
        background-image: url('/img/icons/phone-frame.svg');
        background-size: contain;

        & img {
            width: 100%;
            object-fit: contain;
        }
    }
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.v-select {
    background-color: #fff;
}
</style>
